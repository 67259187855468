<template>
  <div class="waylay">
    <el-card>
      <div slot="header" class="clearfix">
        <div style="margin-bottom:20px;">
          异常管理
        </div>
      </div>
      <!--      显示添加角色对话框-->

      <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="small">
        <el-form-item label="快递单号" prop="mailNo">
          <el-input v-model="form.mailNo" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item label="异常录入时间：">
          <el-date-picker
            v-model="form.badnoDateQuery"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="异常状态：" prop="badStatus">
          <el-select v-model="form.badStatus" placeholder="全部" clearable>
            <el-option v-for="item in badStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="异常归类">
          <el-select v-model="form.badSortOut" placeholder="全部" clearable>
            <el-option v-for="item in badSortOutList" :key="item.status" :label="item.statusDesc" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini" :disabled="isLoading">查询</el-button>
        </el-form-item>
<!--        <el-form-item class="btn-right">-->
<!--          <el-button @click="showDialogWaylayInit()" size="mini" type="danger">添加异常</el-button>-->
<!--        </el-form-item>-->
      </el-form>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="small">
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="deptSortOut" label="部门归属">
            <template slot-scope="scope">
              <span>{{ scope.row.deptSortOut | deptSortOutFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="mailNo" label="快递单号"></el-table-column>
          <el-table-column prop="badUrl" label="物流信息链接">
            <template slot-scope="scope">
              <a :href="scope.row.badUrl" v-if="scope.row.badUrl!==null" target="_blank" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#FFA500;">查看物流信息</a>
              <el-button @click="handleGet(scope.row)" size="mini" type="text" v-else>获取链接</el-button>
            </template>
          </el-table-column>
          <!-- 设置过滤器需要使用作用域插槽获取数据-->
          <el-table-column label="异常录入时间">
            <template slot-scope="scope">
              <span>{{ scope.row.badTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column  label="异常状态">
            <template slot-scope="scope">
              <span>{{ scope.row.badStatus | badStatusFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column label="异常归类">
            <template slot-scope="scope">
              <span>{{ scope.row.badSortOut | badSortOutFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="{row}">
              <el-button @click="tackList(row)" type="success" size="mini">跟进记录</el-button>
              <el-button @click="tackCreat(row)" type="warning" size="mini" v-if="'2'!==row.badStatus">跟进</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10,50,100,200]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>

      <!--  添加拦截弹窗-->
      <div v-if="dialogVisibleBadnoInit">
        <el-dialog
          title="添加异常"
          :visible.sync="dialogVisibleBadnoInit"
          width="60%"
          :before-close="handleCancelWaylayInit">
          <!--将添加与编辑功能单独封装到组件中-->
          <create-bad-no  @cancel="handleCancelWaylayInit" @success="handleSuccessWaylayInit"></create-bad-no>
        </el-dialog>
      </div>

      <!--  跟进弹窗-->
      <div v-if="dialogVisibleTack">
        <el-dialog
          title="添加跟进"
          :visible.sync="dialogVisibleTack"
          width="60%"
          :before-close="handleCancelTack">
          <!--将添加与编辑功能单独封装到组件中-->
          <badno-tack :bad-status="badStatus" :bad-sort-out="badSortOut" :mail-no="mailNo" @cancel="handleCancelTack" @success="handleSuccessTack"></badno-tack>
        </el-dialog>
      </div>

      <!--  跟进记录弹窗-->
      <div v-if="dialogVisibleTackList">
        <el-dialog
          title="跟进记录"
          :visible.sync="dialogVisibleTackList"
          width="60%"
          :before-close="handleCancelTackList">
          <!--将添加与编辑功能单独封装到组件中-->
          <badno-tack-list  :mail-no="mailNo" @cancel="handleCancelTackList" ></badno-tack-list>
        </el-dialog>
      </div>

    </el-card>

  </div>
</template>

<script>
import {dateFormate} from '@/utils/dateUtil'
import {findAllBadnoStatus,findAllBadnoSortOut} from '@/services/status'
import {findAllBadNoInfoByPage,getBadUrl} from '@/services/logistics'
import CreateBadNo from './components/CreateBadNo'
import BadnoTack from "./components/BadnoTack";
import {badStatusToCn,badSortOutToCn,deptSortOutToCn} from '@/utils/enums'
import BadnoTackList from "./components/BadnoTackList";
import {getUserInfo} from '@/services/user'

export default {
  name: 'badno',
  components: {BadnoTackList, BadnoTack, CreateBadNo },
  data() {
    return {
      userInfo: {},
      tableData: [],
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      // 数据总数
      dataTotal: 0,
      isLoading: false,
      // 用于存储拦截状态列表
      badStatusList: [],
      badSortOutList: [],
      // 显示或隐藏对话框
      dialogVisibleBadnoInit: false,

      // 显示或隐藏对话框
      dialogVisibleTack: false,

      dialogVisibleTackList: false,
      //控制对话框的功能状态
      badSortOut: null,
      // 存储正在编辑的角色ID
      badStatus: null,
      mailNo: null,
    }
  },
  methods: {
    async getH5Url(row) {
      // 获取物流信息链接
      const {data} = await getBadUrl(row)
      if (200 !== data.code) {
        this.$message.error('获取物流信息链接失败')
      }
      await this.loadAllBadNoInfoByPage()
    },
    handleGet(row) {
      this.getH5Url(row)
    },
    // 监听子组件的添加状态，成功时触发
    handleSuccessWaylayInit () {
      // 隐藏对话框
      this.dialogVisibleBadnoInit = false
      // 刷新列表
      this.loadAllBadNoInfoByPage()
    },

    // 监听子组件的取消状态
    handleCancelWaylayInit () {
      // 隐藏对话框
      this.dialogVisibleBadnoInit = false
    },

    // 监听子组件的添加状态，成功时触发
    handleSuccessTack () {
      // 隐藏对话框
      this.dialogVisibleTack = false
      // 刷新列表
      this.loadAllBadNoInfoByPage()
    },

    // 监听子组件的取消状态
    handleCancelTack () {
      // 隐藏对话框
      this.dialogVisibleTack = false
      this.badStatus = null
      this.badSortOut = null
      this.mailNo = null
    },

    // 监听子组件的取消状态
    handleCancelTackList() {
      // 隐藏对话框
      this.dialogVisibleTackList = false
      this.mailNo = null
    },

    //
    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadAllBadNoInfoByPage()
    },
    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadAllBadNoInfoByPage()
    },

    // 添加筛选后的数据查询
    async onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form.currentPage = 1
      await this.loadAllBadNoInfoByPage()
    },

    async loadBadnoStatus() {
      // 查询异常状态列表
      const {data} = await findAllBadnoStatus()
      if (200 === data.code) {
        this.badStatusList = data.data
      } else {
        this.$message.error('查询拦截状态列表失败')
      }
    },
    async loadbadSortOut() {
      // 查询拦截归类列表
      const {data} = await findAllBadnoSortOut()
      if (200 === data.code) {
        this.badSortOutList = data.data
      } else {
        this.$message.error('查询拦截归类列表失败')
      }
    },
    async loadAllBadNoInfoByPage() {
      // 开始加载数据
      this.isLoading = true
      await this.loadUserInfo()
      this.form.deptSortOut = this.userInfo.company
      if (this.form && this.form.badnoDateQuery) {
        let badnoDateBegin = this.form.badnoDateQuery[0]
        let badnoDateEnd = this.form.badnoDateQuery[1]
        this.form.badnoDateBegin = badnoDateBegin
        this.form.badnoDateEnd = badnoDateEnd
      }
      // 分页多条件查询快递单号信息
      const {data} = await findAllBadNoInfoByPage(this.form)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },
    // 显示对话框
    showDialogWaylayInit() {
      this.dialogVisibleBadnoInit = true
    },

    // 显示跟进对话框
    tackCreat(row) {
      this.badStatus = row.badStatus
      this.badSortOut = row.badSortOut
      this.mailNo = row.mailNo
      this.dialogVisibleTack = true
    },
     // 显示跟进记录对话框
    tackList(row) {
      this.mailNo = row.mailNo
      this.dialogVisibleTackList = true
    },

    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
  },
  created() {
    if (this.$route.query&&this.$route.query.badStatus) {
      this.form.badStatus = this.$route.query.badStatus
    }
    this.loadBadnoStatus()
    this.loadbadSortOut()
    this.loadAllBadNoInfoByPage()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    },
    // 拦截状态过滤器
    badStatusFormate(code) {
      return badStatusToCn(code)
    },

    // 拦截状态过滤器
    badSortOutFormate(code) {
      return badSortOutToCn(code)
    },

    deptSortOutFormate(code) {
      return deptSortOutToCn(code)
    },
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}

.btn-right {
  float: right;
}

.el-table {
  margin-top: 10px;
}

.btn-div {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
