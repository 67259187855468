import request from '@/utils/request'

// 查询快递状态列表
export function findAllLogisticsStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllLogisticsStatus`
  })
}

// 查询拦截状态列表
export function findAllLaywayStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllLaywayStatus`
  })
}

// 查询拦截归类列表
export function findAllWaylaySortOut() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllWaylaySortOut`
  })
}

// 查询打包员列表
export function findAllPackUser() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllPackUser`
  })
}

// 物流订阅状态状态列表
export function findAllSubscribeStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllSubscribeStatus`
  })
}

// 查询异常状态列表
export function findAllBadnoStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllBadnoStatus`
  })
}

// 查询异常归类列表
export function findAllBadnoSortOut() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllBadnoSortOut`
  })
}


// 查询退回状态列表
export function findAllBackStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllBackStatus`
  })
}

// 查询退回归类列表
export function findAllBackSortOut() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllBackSortOut`
  })
}

// 查询部门归属列表
export function findAllDeptSortOut() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllDeptSortOut`
  })
}

// 查询生产状态列表
export function findAllProductStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllProductStatus`
  })
}

// 查询生产类型列表
export function findAllProductType() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllProductType`
  })
}

// 查询置顶类型列表
export function findAllTopStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllTopStatus`
  })
}

// 查询需方类型列表
export function findAllNeedStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllNeedStatus`
  })
}


// 查询所属平台列表
export function findAllPlatformType() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllPlatformType`
  })
}


// 查询订单状态列表
export function findAllOrderStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllOrderStatus`
  })
}


// 查询发货类型列表
export function findAllSendTypeStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllSendTypeStatus`
  })
}

// 查询商品状态列表
export function findAllGoodsStatus() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllGoodsStatus`
  })
}

// 查询库存初始状态列表
export function findAllIniStock() {
  return request({
    method: 'get',
    url: `/auth/statusInfo/findAllIniStock`
  })
}
